export class Common {
    //
    // Select a text by classname
    //
    static selectTextByClassName(className) {
        const doc = document;
        const text = doc.getElementsByClassName(className)[0];
        let range, selection;

        if (doc.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    //
    // Show loader
    //
    static showLoader() {
        $("#loader").show(0);
    }

    //
    // Hide loader
    //
    static hideLoader() {
        $("#loader").hide(0);
    }
}
