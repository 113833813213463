/// <reference path="../typings/jquery/jquery.d.ts" />
/// <reference path="../typings/custom-typings.d.ts" />

import { Common } from "./common";

export class Application {
  searchTimeout: number = null;
  page = 0;
  canGetMoreResults = true;
  resizeTimeout: number = null;
  columns: number = 3;
  isMySelectionPage: boolean = false;
  isDetailsPage: boolean = false;
  $masonry: JQuery = null;
  $searchForm: JQuery = null;
  clickEvent: string = null;
  clickedImageId: number = null;
  touchduration = 200; // For long touch on image in search result
  timerInterval = 0; // For long touch on image in search result
  loading = false;
  //
  // Constructor
  //
  constructor() {
    const self: Application = this;

    // When document is loaded
    $(() => {
      // Set click event depending on touch device or not
      self.clickEvent = Modernizr.touch ? "touchstart" : "click";

      // Check for SVG as img support
      if (!Modernizr.svgasimg) {
        $("body").addClass("no-svg");
        $("img[src$='.svg']").each((index, value) => {
          var $img = $(value);
          var src = $img.attr("src");
          $img.attr("src", $img.attr("src").substring(0, src.length - 3) + "png");
        });
      }

      self.isDetailsPage = $("#detailsPage").length > 0;

      self.$searchForm = $(".search-wrapper form");

      self.initMediaGallery();
      self.initSocialIcons();
      self.initEvents();
      self.makeCategoryHiearchy();
      self.isMySelectionPage = $(".my-selection-page").length > 0;

      self.columns = $(".search-filter-wrapper li.columns.active a").data("columns");

      self.clickedImageId = 0;

      if ($(document).width() <= 480) {
        self.columns = 2;
      }

      // Hide paging if javascript is enabled (do it with scroll instead)
      $(".paging").hide();

      //// Add droppable event for my selection
      //$(".my-selection").droppable({
      //    tolerance: "pointer",
      //    drop: (event, ui) => {
      //        self.addToMySelection($(ui.helper).find("a").data("media-id"));
      //        $(ui.helper).remove();
      //    }
      //});

      // Disable get more results in my selection
      if ($(".my-selection-page").length) self.canGetMoreResults = false;

      // Possible to get more if result is more than 24
      if ($("li.image-gallery-list-item").length < 24) self.canGetMoreResults = false;
    });
  }

  /**
   * Timer for long touch
   * @param interval
   * @param target
   */
  timer(interval, target) {
    const self: Application = this;

    interval--;

    if (interval >= 0) {
      self.timerInterval = setTimeout(() => {
        this.timer(interval, target);
      });
    } else {
      self.taphold($(target));
    }
  }

  /**
   * Touch start for long touch
   * @param ev
   */
  touchstart(ev) {
    const self: Application = this;
    self.timer(self.touchduration, ev.currentTarget);
  }

  /**
   * Touch end for long touch
   * @param e
   */
  touchend(e) {
    const self: Application = this;

    $(e.currentTarget).closest(".get-details-link").removeClass("hover");
    clearTimeout(self.timerInterval);
  }

  /**
   * Tap hold for long touch
   * @param e
   */
  taphold($element) {
    const self: Application = this;

    // Heart icon
    const $heartIcon = $element.next(".add-to-my-selection");

    // Hold on element
    if ($heartIcon.length) {
      self.addToMySelection($heartIcon.data("media-id"));
    }
  }

  /**
   * Add correct layout to media library
   */
  initMediaGallery() {
    const self: Application = this;

    // Load masonry for image gallery
    const $container = $(".image-gallery-list");
    $container.imagesLoaded(() => {
      self.$masonry = $container.masonry({
        itemSelector: "li.image-gallery-list-item",
        horizontalOrder: false,
        transitionDuration: 50,
      });

      // Reload masonry
      self.$masonry.masonry("layout");
      $("img.lazyload").lazyload();
    });
  }

  //
  // Add social icons
  //
  initSocialIcons() {
    const self: Application = this;

    // Social icons
    $(document).on(self.clickEvent, ".share-button", (e) => {
      let detailsShareLink = $(".media-gallery-dialog").data("url");
      var $shareButton = $(e.currentTarget);
      var facebook = $shareButton.hasClass("facebook");
      var twitter = $shareButton.hasClass("twitter");
      var linkedin = $shareButton.hasClass("linkedin");
      var link = $shareButton.hasClass("link");

      // If share my selection then first create share for all media items and add this id to URL
      if (self.isMySelectionPage && !$shareButton.closest(".media-gallery-dialog-wrapper").length) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", `/MySelection/ShareSelection?formatId=${$(".download-link").eq(0).data("format-id")}`, false);
        xmlHttp.send(null);
        detailsShareLink = xmlHttp.responseText;
      }

      // Facebook
      if (facebook) window.open("https://facebook.com/sharer/sharer.php?u=" + detailsShareLink);
      // Twitter
      else if (twitter) window.open("https://twitter.com/share?url=" + detailsShareLink + "&text=" + $(".details-title").text().trim());
      // Linked in
      else if (linkedin) window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + detailsShareLink);
      // Linked in
      else if (link) {
        window.open(detailsShareLink);
      }

      return false;
    });
  }

  //
  // Add events for search form
  //
  initEvents() {
    const self: Application = this;

    // Click events on category checkboxes
    $(document).on("mouseup", ".search-link, .category-checkbox", () => {
      self.submitSearchForm(false);

      return false;
    });

    // Click events on category checkboxes
    $(document).on(self.clickEvent, ".fictive-input", (ev) => {
      let $checkboxElement = $(ev.currentTarget).prev(".category-checkbox");

      if ($checkboxElement.attr("checked")) {
        $checkboxElement.removeAttr("checked");
        $(ev.currentTarget).removeClass("checked");
      } else {
        $checkboxElement.attr("checked", "checked");
        $(ev.currentTarget).addClass("checked");
      }
      self.submitSearchForm(false);

      return false;
    });

    // Get more result on scroll to bottom
    const scrollTolerance = 50;
    $(window).scroll(async () => {
      let scrollTop = $(window).scrollTop();
      let documentHeight = $(document).height();
      let windowHeight = $(window).height();
      let scrollHeight = documentHeight - windowHeight;
      let scrollThreshold = scrollTop + scrollTolerance;
      if (scrollThreshold >= scrollHeight && self.canGetMoreResults) {
        if (self.loading) return;
        self.loading = true;
        // console.log("scrollThreshold:", scrollThreshold, " scrollHeight:", scrollHeight, " canGetMoreResults:", self.canGetMoreResults);
        await self.getMoreResults();
        self.loading = false;
      }
    });

    // Window resize function
    $(window).resize(() => {
      clearTimeout(self.resizeTimeout);

      self.resizeTimeout = setTimeout(() => {
        // Reload masonry
        self.$masonry.masonry("layout");
      }, 50);

      if ($(document).width() <= 480) {
        self.columns = 2;
      } else {
        self.columns = $(".search-filter-wrapper li.columns.active a").data("columns");
      }
    });

    // Event for categories buttons
    $(document).on(self.clickEvent, ".show-child-categories", (e) => {
      const $listItem = $(e.target).closest("li");

      $listItem.toggleClass("opened");

      return false;
    });

    $(document).on(self.clickEvent, "body, a", (e) => {
      if (!$(e.target).parents(".categories").length) {
        $(".categories").removeClass("active");
      }
    });

    // Event show/hide categories on click
    $(document).on(self.clickEvent, ".categories > a", (e) => {
      $(e.currentTarget).parent().toggleClass("active");

      return false;
    });

    // Event to search for category from details view
    $(document).on(self.clickEvent, ".category-link", (e) => {
      var categoryId = $(e.currentTarget).data("category-id");

      // Clear values
      $("input[type=text], input[type=hidden]", ".search-wrapper form").val("");

      // Clear checkbox
      $("input[type=checkbox]", ".search-wrapper form").prop("checked", false);
      $(".fictive-input", ".search-wrapper form").removeClass("checked");

      // Check category checkbox
      $(`input[type=checkbox][value=${categoryId}]`, ".search-wrapper form").prop("checked", true);
      $(`.fictive-input[data-value=${categoryId}]`, ".search-wrapper form").addClass("checked");

      $(".close-button").click();
      self.submitSearchForm();
      return false;
    });

    // Event for long touch on details link
    $(document).on("touchstart", ".get-details-link img", (e) => {
      self.touchstart(e);
    });

    // Event for long touch on details link
    $(document).on("touchend", ".get-details-link img", (e) => {
      self.touchend(e);
    });

    // Event for mouse over on details link
    $(document).on("mouseover", ".get-details-link", (e) => {
      $(e.currentTarget).addClass("hover");
    });

    // Event for mouse out on details link
    $(document).on("mouseout", ".get-details-link", (e) => {
      $(e.currentTarget).removeClass("hover");
    });

    // Event for click details link
    $(document).on(self.clickEvent, ".get-details-link", (e) => {
      $(e.currentTarget).removeClass("hover");
      const $clickedElement = $(e.currentTarget);
      self.clickedImageId = $clickedElement.data("media-id");

      self.performAjaxRequest(
        "/MediaGallery/DetailsDialog?mediaId=" + $clickedElement.data("media-id") + (self.isMySelectionPage ? "&myselection=True" : ""),
        null,
        $(".media-details-wrapper"),
        () => {
          var spaceForDetails = 32;

          // Set korrekt padding for details
          if ($(".details-title").length) spaceForDetails += $(".details-title").height();

          if (!$(".not-allow-download").length) spaceForDetails += 80;

          $(".details-information-wrapper").css("padding-bottom", +spaceForDetails);
          $(".media-gallery-dialog-content").attr("tabindex", "-1");
          $(".media-gallery-dialog-content").focus();

          // Set aria hidden of main content behind
          $("main, header, footer, .cookies-wrapper").attr("aria-hidden", "true");
        },
        false,
        null,
        false
      );

      return false;
    });

    // Event for details image click
    $(document).on(self.clickEvent, ".details-image img", () => {
      $(".preview-image img").attr("src", $("preview-image").data("image-url"));
      $(".media-gallery-dialog ").attr("aria-hidden", "true");

      $(".preview-image").attr("aria-hidden", "false");
      $(".preview-image").show();
      return false;
    });

    // Event for categories buttons
    $(document).on(self.clickEvent, ".download-link", (e) => {
      const $clickedElement = $(e.currentTarget);
      const downloadUrl: string = $clickedElement.data("download-url");
      if (!downloadUrl) return true;
      self.performAjaxRequest(
        "/MediaGallery/TermsDialog?downloadUrl=" + encodeURI(downloadUrl),
        null,
        ".terms-dialog-wrapper",
        (result) => {
          $(".media-details-wrapper > .media-gallery-dialog").attr("aria-hidden", "true");
          $(".terms-dialog-wrapper").attr("aria-hidden", "false");
          $(".terms-dialog-wrapper").show();
        },
        false,
        null,
        false
      );

      return false;
    });

    // Event for close button
    $(document).on(self.clickEvent, ".close-button", (e) => {
      if (self.isDetailsPage) window.location.href = "/";

      if ($(".preview-image").is(":visible")) {
        $(".media-gallery-dialog ").attr("aria-hidden", "false");
        $(".preview-image").hide().attr("aria-hidden", "true");
      } else if ($(".terms-dialog-wrapper").is(":visible")) {
        $(".media-gallery-dialog").attr("aria-hidden", "false");
        $(".terms-dialog-wrapper").hide();
        $(".terms-dialog-wrapper").attr("aria-hidden", "true");
      } else {
        this.closeDetailsDialog(e, self.clickedImageId);
      }
      return false;
    });

    $(document).keyup((e) => {
      if (e.key === "Escape") {
        this.closeDetailsDialog(e, self.clickedImageId);
      }
      e.stopPropagation();
    });

    // Event for close button
    $(document).on(self.clickEvent, ".accept-button", (e) => {
      $(e.target).closest(".dialog-wrapper").last().html("");
      $(".terms-dialog-wrapper").hide();
      $(".media-gallery-dialog").attr("aria-hidden", "false");
      $(".media-gallery-dialog-content").attr("tabindex", "-1").focus();
    });

    // Event for removing cookie information
    $(document).on(self.clickEvent, ".accept-cookies-link", (e) => {
      self.performAjaxRequest(
        $(e.currentTarget).attr("href"),
        null,
        null,
        () => {
          $(".cookies-wrapper").remove();
        },
        false,
        null,
        false
      );

      return false;
    });

    // Event for sorting
    $(document).on(self.clickEvent, ".change-sort-order-link", (e) => {
      const orderBy: string = $(e.target).data("order-by");

      $(".order-by-input").val(orderBy);

      self.submitSearchForm();

      return false;
    });

    // Event for show image or map iframe
    $(document).on(self.clickEvent, ".show-on-map, .show-image", (e) => {
      const showMap = $(e.target).hasClass("show-on-map");

      const $detailsImage = $(".details-image img");

      const $detailsMap = $(".details-image iframe");

      if (showMap) {
        $detailsImage.hide();
        $detailsMap.show();
        $(".show-on-map").hide();
        $(".show-image").show();
      } else {
        $detailsImage.show();
        $detailsMap.hide();
        $(".show-on-map").show();
        $(".show-image").hide();
      }

      return false;
    });

    // Event for change columns
    $(document).on(self.clickEvent, ".change-columns-link", (e) => {
      const $clickedButton = $(e.currentTarget);
      const columns: number = $(e.currentTarget).data("columns");

      self.columns = columns;

      if ($(document).width() <= 480) {
        self.columns = 2;
      }

      const callBackFunction = self.isMySelectionPage
        ? () => {
            self.performAjaxRequest(
              "/MySelection/MySelectionMediaPartial/",
              null,
              ".my-selection-gallery-wrapper",
              () => {
                // Reload masonry
                self.initMediaGallery();
              },
              false,
              null,
              false
            );

            $(".search-filter-wrapper li").removeClass("active");
            $clickedButton.parent().addClass("active");
          }
        : () => {
            $(".search-filter-wrapper li").removeClass("active");
            $clickedButton.parent().addClass("active");
            self.submitSearchForm();
          };

      self.performAjaxRequest(`/MediaGallery/ChangeNumberOfColumns/?columns=${columns}`, null, null, callBackFunction, false, "POST", false);

      return false;
    });

    // Event for remove search property
    $(document).on(self.clickEvent, ".remove-search-property", (e) => {
      const $clickedElement = $(e.currentTarget);

      const categoryId = $clickedElement.data("category-id");

      if (categoryId != undefined) {
        $(`.category-input-${categoryId}`).prop("checked", false).removeClass("checked");
      } else {
        $(".search-term").val("");
      }

      self.submitSearchForm();

      return false;
    });

    // Event for add to my selection
    $(document).on(self.clickEvent, ".add-to-my-selection", (e) => {
      self.addToMySelection($(e.currentTarget).data("media-id"));

      return false;
    });

    // Event for clear search
    $(document).on(self.clickEvent, ".clear-search", () => {
      // Clear values
      $("input[type=text], input[type=hidden]", ".search-wrapper form").val("");

      // Clear checkbox
      $("input[type=checkbox]", ".search-wrapper form").prop("checked", false);
      $(".fictive-input", ".search-wrapper form").removeClass("checked");

      self.submitSearchForm();

      return false;
    });
    // Event for categories buttons
    $(document).on(self.clickEvent, ".get-more-results-link", () => {
      self.getMoreResults();

      return false;
    });

    // Submit event to perform ajaz search
    $(document).on("submit", ".search-wrapper form", () => {
      if ($(".my-selection-page").length) return true;

      const formData = self.$searchForm.serialize();

      self.canGetMoreResults = true;
      self.page = 0;

      self.performAjaxRequest(
        "/MediaGallery/SearchMediaPartial",
        formData,
        ".search-media-partial-wrapper",
        () => {
          self.initMediaGallery();

          history.pushState({}, null, window.location.pathname + "?" + self.$searchForm.serialize());
        },
        false,
        null,
        false
      );

      return false;
    });
  }

  closeDetailsDialog(e: any, imageId: number) {
    $(e.target).closest(".dialog-wrapper").last().html("");
    $("main, header, footer, .cookies-wrapper").attr("aria-hidden", "false");
    $(".active-dialog").removeClass("active-dialog");
    $(".media-gallery-dialog").attr("aria-hidden", "true");
    $(`#media-${imageId}`).attr("tabindex", "-1").css("outline", "none").focus();
  }

  //
  // Add item to my selection
  //
  addToMySelection(mediaId) {
    const self: Application = this;

    self.performAjaxRequest(
      "/MediaGallery/AddOrRemoveToMySelectionPartial?mediaId=" + mediaId,
      null,
      null,
      (result) => {
        var mySelectionCount = parseInt($(".my-selection-count").text());
        if (isNaN(mySelectionCount)) mySelectionCount = 0;

        if (result === "True") {
          mySelectionCount += 1;
        } else {
          mySelectionCount -= 1;
        }

        $(".my-selection-count").text(mySelectionCount);
        $(`.add-to-my-selection[data-media-id=${mediaId}]`).toggleClass("active");
      },
      false,
      null,
      true
    );
  }

  //
  // Get more results
  //
  getMoreResults(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const self: Application = this;
      if (self.$masonry == null) return resolve();

      Common.showLoader();
      if (self.canGetMoreResults && !$(".my-selection-page").length) {
        self.page++;
        const formData = $(".search-form").serialize();

        const url = `/MediaGallery/SearchMediaPartial/?${formData}`;
        const urlWithSkip = url + "&page=" + self.page;
        const container = ".image-gallery-list";
        const $container = $(container);

        self.performAjaxRequest(
          urlWithSkip,
          null,
          null,
          (result) => {
            // If empty result then return
            if (result.length < 5) {
              self.canGetMoreResults = false;
              Common.hideLoader();
              resolve();
              return;
            }

            var $jQueryResult = $("<div>" + result + "</div>").find("> li");
            const items = $jQueryResult.toArray();

            // If result contains less than 24 items, then you can not get more results
            if (items.length < 24) {
              self.canGetMoreResults = false;
            }

            // Hide list items before masonry for a better effect
            $jQueryResult.hide();

            self.$masonry.append(items).masonry("appended", items);

            $container.imagesLoaded(() => {
              self.$masonry.masonry("layout");
              $("img.lazyload").lazyload();
            });

            Common.hideLoader();
            resolve();
          },
          false,
          null,
          false
        );
      }
    });
  }

  //
  // Perform ajax request
  //
  performAjaxRequest(url, data, updateWrapper, successCallback, append, method, addToSelection) {
    if (!addToSelection) {
      Common.showLoader();
    }

    let $updateWrapper: JQuery = updateWrapper;

    if (!(Object instanceof jQuery)) $updateWrapper = $(updateWrapper);

    $(".active-dialog").removeClass("active-dialog");

    $updateWrapper.addClass("active-dialog");
    $updateWrapper.show();

    $.ajax({
      url: url,
      data: data,
      method: method ? method : "GET",
      cache: false,
      success(result) {
        Common.hideLoader();

        if ($updateWrapper) {
          if (append) $updateWrapper.eq(0).append(result);
          else $updateWrapper.eq(0).html(result);
        }

        if (successCallback) successCallback(result);
      },
      error() {
        Common.hideLoader();

        if (successCallback) successCallback(null);
      },
    });
  }

  //
  // Submit search form and reset the earlier event (wait 150ms)
  //
  submitSearchForm(updateCategoryHiearchy: boolean = true) {
    const self: Application = this;

    self.searchTimeout = setTimeout(() => {
      $(".search-wrapper form").submit();

      if (updateCategoryHiearchy) self.makeCategoryHiearchy();
    }, 50);
  }

  //
  // Show message for user
  //
  showMessage(message, isErrorMessage) {
    $(".message").html(message);

    if (isErrorMessage === false) $(".message").addClass("success").removeClass("error");
    else if (isErrorMessage === true) $(".message").addClass("error").removeClass("success");

    $(".message").show().delay(5000).fadeOut();
  }

  //
  // Open choosen categories parents
  //
  makeCategoryHiearchy() {
    $(".categories li").removeClass("opened");

    // Open up child categories if any is choosen
    $(".category-checkbox[checked]").each((index) => {
      let $element = $(".category-checkbox:checked").eq(index);
      $element.closest("li").parentsUntil(".categories", "li").addClass("opened");
    });
  }
}

var application = new Application();
